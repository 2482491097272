import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/login/Login";
import Console from "./pages/console";
import Staff from "./pages/console/staff/Staff";
import Service from "./pages/console/service/Service";
import Layout from "./pages/console/layout/Layout";
import MobileUnit from "./pages/console/mobileunit/MobileUnit";
import AuthGuard from "./components/RouteGuard/AuthGuard";
import RedirectIfAuthenticatedGuard from "./components/RouteGuard/RedirectIfAuthenticatedGuard";
import Bookings from "./pages/console/booking/Bookings";
import Subadmin from "./pages/console/subadmin/Subadmin";
import BookingHisory from "./pages/console/bookinghistory/BookingHistory";
import Dashboard from "./pages/console/dashboard/Dashboard";
function App() {
  return (
    <div className="App theme-light">
      {/* <Login /> */}
      {/* <Dashboard/> */}
      <Router>
        <Routes>
          <Route element={<RedirectIfAuthenticatedGuard />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route element={<AuthGuard />}>
            <Route element={<Layout />}>
              <Route path="/console/staff" element={<Staff />} />
              <Route path="/console/dashboard" element={<Dashboard />} />
              <Route path="/console/services" element={<Service />} />
              <Route path="/console/mobileunit" element={<MobileUnit />} />
              <Route path="/console/booking" element={<Bookings />} />
              <Route path="/console/subadmin" element={<Subadmin />} />
              <Route
                path="/console/bookinghistory"
                element={<BookingHisory />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
