import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import EngineeringIcon from "@mui/icons-material/Engineering";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
export const SidebarList = [
  {
    id: 0,
    icon: <EngineeringIcon />,
    label: "Dashboard",
    route: "/console/dashboard",
  },
  {
    id: 1,
    icon: <EngineeringIcon />,
    label: "Staff",
    route: "/console/staff",
  },
  {
    id: 2,
    icon: <MiscellaneousServicesIcon />,
    label: "Services",
    route: "/console/services",
  },
  {
    id: 3,
    icon: <CarRepairIcon />,
    label: "Mobile Unit",
    route: "/console/mobileunit",
  },
  {
    id: 4,
    icon: <InsertInvitationIcon />,
    label: "Bookings",
    route: "/console/booking",
  },
  {
    id: 5,
    icon: <SupervisorAccountIcon />,
    label: "Booking History",
    route: "/console/bookinghistory",
  },
  {
    id: 6,
    icon: <SupervisorAccountIcon />,
    label: "Sub Admin",
    route: "/console/subadmin",
  },
];
